import React from 'react'
import styled from 'styled-components'

const Dots = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  position: absolute;
  bottom: 15px;
  left: calc(50% - 100px);
  z-index: 3;
  min-width: 200px;
  text-align: center;
  .dot {
    width: 15px;
    height: 15px;
    margin: 5px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.3);
    display: inline-block;
    outline: none;
    border: 1px solid ${props => props.theme.white};
    &:hover {
      cursor: pointer;
    }
    &.is-current {
      border: 1px solid ${props => props.theme.red};
      background: ${props => props.theme.red};
    }
  }
`

const SliderControls = ({currentSlideNum, slides, slideShowNavigate}) => (
  <Dots>
    {slides.map((slide, i) => {
      let isCurrent = ''
      if (i === currentSlideNum) {
        isCurrent = 'is-current'
      }
      return (
        <a
          onClick={() => slideShowNavigate(i)}
          className={`dot ${i} ${isCurrent}`}
          key={i}
          role="button"
          tabIndex={0}
        >
          &nbsp;
        </a>
      )
    })}
  </Dots>
)
export default SliderControls
