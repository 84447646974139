/* eslint-disable react/no-did-update-set-state */
import React, {Component} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import arrow from '../../../images/icons/icon-arrow-circle-red.svg'
import SliderControls from './sliderControls'

const Slide = styled.div`
  background-color: ${props => props.theme.dark};
  position: relative;
`
const SlideInner = styled.div`
  position: relative;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
`

const ImageAnimation = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 600px;
  transition: opacity 150ms ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  @media (max-width: 1024px) {
    height: 360px;
  }
`
const MainImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  display: none;
  &.mobile-img {
    @media (max-width: 600px) {
      display: block;
    }
  }
  &.desktop-img {
    @media (min-width: 600px) {
      display: block;
    }
  }
`
const SliderContent = styled.div`
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 3;
  padding: 20px 30px 30px 30px;
  left: 20px;
  top: 200px;
  width: 320px;
  border-radius: 10px;
  h2 {
    margin: 0 0 25px 0 !important;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 35px;
    text-align: left;
    color: ${props => props.theme.grey1};
  }
  p {
    margin: 0;
    background: ${props => props.theme.red};
    padding: 10px;
    text-align: center;
    border-radius: 50px;
    &:hover {
      background: ${props => props.theme.redHover};
    }
    a,
    span {
      color: ${props => props.theme.white};
      position: relative;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      outline: none;
    }
  }
  @media (max-width: 1024px) {
    top: 120px;
  }
  @media (max-width: 768px) {
    top: 250px;
    width: calc(100% - 40px);
    padding: 20px 30px;
    h2 {
      font-size: 25px;
    }
  }
  @media (max-width: 360px) {
    top: 240px;
  }
`

class Slider extends Component {
  state = {
    imageVisible: true,
    currentSlideNum: 0,
  }

  scrollOccured = () => {
    if (typeof this.interval === 'undefined') {
      setTimeout(() => this.changeSlide('n'), 300)
      this.interval = setInterval(() => this.changeSlide('n'), 8000)
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollOccured)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollOccured)
    clearInterval(this.interval)
  }

  slideShowClick = direction => {
    this.setState({imageVisible: false})
    this.changeSlide(direction)
  }

  slideShowNavigate = nextSlideNum => {
    // Reset Values
    clearInterval(this.interval)
    this.setState({imageVisible: false})
    // change slide after slight delay for animation
    setTimeout(() => {
      this.setState({imageVisible: true})
      if (nextSlideNum < this.props.slides.length) {
        this.setState({currentSlideNum: nextSlideNum, imageVisible: true})
      } else {
        // if we've reached the end, go back to start
        this.setState({currentSlideNum: 0, imageVisible: true})
      }
    }, 300)
    this.interval = setInterval(() => this.changeSlide('n'), 8000)
  }

  changeSlide = direction => {
    const {currentSlideNum} = this.state
    // Calculate Next Slide
    let nextSlideNum
    if (direction === 'n') {
      // go forward one slide
      nextSlideNum = currentSlideNum + 1
    } else if (currentSlideNum <= 0) {
      // loop to end
      nextSlideNum = this.props.slides.length - 1
    } else {
      // Go back one slide
      nextSlideNum = currentSlideNum - 1
    }
    // Go to that slide
    this.slideShowNavigate(nextSlideNum)
  }

  render() {
    const {imageVisible, currentSlideNum} = this.state
    const {slides} = this.props
    const currentSlide = slides[currentSlideNum]

    // Check to use desktop or mobile images
    const mobileFluid =
      currentSlide.slider_image_mobile &&
      currentSlide.slider_image_mobile.localFile.childImageSharp.fluid
    const desktopFluid =
      currentSlide.slider_image &&
      currentSlide.slider_image.localFile.childImageSharp.fluid

    return (
      <Slide>
        <SliderControls
          currentSlideNum={currentSlideNum}
          slides={slides}
          slideShowNavigate={this.slideShowNavigate}
        />
        <SlideInner>
          <SliderContent icon={arrow}>
            <h2 dangerouslySetInnerHTML={{__html: currentSlide.slider_title}} />
            <Link to={`/${currentSlide.button_link}`}>
              <p>
                <span>{currentSlide.button_text}</span>
              </p>
            </Link>
          </SliderContent>
        </SlideInner>
        <ImageAnimation visible={imageVisible}>
          <MainImage className="mobile-img" src={mobileFluid.srcWebp} />
          <MainImage className="desktop-img" src={desktopFluid.srcWebp} />
        </ImageAnimation>
      </Slide>
    )
  }
}

export default Slider
